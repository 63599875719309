import React from 'react'

// Gatsby
import { Link } from 'gatsby'

const About = () => (
  <div className='about'>
    <h1 className='title'>
      Merhaba, ben Yasin
      <span className='about__icon' role='img' aria-label='hi'>
        👋
      </span>
    </h1>
    <p>
      Frontend development ile ilgili güncel yazılara, içeriklere, notlara ve ipuçlarına ulaşabileceğiniz kişisel blog
      sayfama hoş geldiniz. Genellikle html, css, javascript, react ve modern frontend development ile ilgili içerikler
      paylaşıyor olacağım.
    </p>
    <p>
      İstek ve önerilerinizi <Link to='/about'>hakkında</Link> sayfasındaki sosyal medya hesaplarından iletebilirsiniz.
    </p>
  </div>
)

export default About
