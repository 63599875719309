import React, { Fragment } from 'react'

// Gatsby
import { graphql } from 'gatsby'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Components
import About from '@components/About'
import PostList from '@components/PostList'
import SEO from '@components/SEO'

const Index = ({ data: { posts, notes } }) => {
  const { edges: postsEdges } = posts
  const { edges: notesEdges } = notes

  return (
    <Fragment>
      <SEO
        title='Frontend development ile ilgili güncel ve faydalı yazılar, içerikler, ipuçları'
        description='Frontend development ile ilgili güncel ve faydalı bilgiler bulabileceğiniz kişisel blog.'
        keywords='Blog, Frontend, Development, Yazılım, Html, Css, Sass, Javascript, Jquery, ES6, React, Gatsby, Git, Best Practices, İpuçları, Nasıl Yapılır, Nasıl Kullanılır'
      />
      <MainLayout>
        <div className='main__content'>
          <About />
        </div>
        <div className='main__content'>
          <PostList edges={postsEdges} title='Yazılar' />
        </div>

        <div className='main__content'>
          <PostList edges={notesEdges} title='Notlar' />
        </div>
      </MainLayout>
    </Fragment>
  )
}

export default Index

export const indexQuery = graphql`
  query indexQuery {
    posts: allMdx(
      sort: { order: DESC, fields: [frontmatter___createdAt] }
      filter: { frontmatter: { articleType: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            createdAt(formatString: "DD MMMM YYYY", locale: "tr")
            title
            tags
          }
        }
      }
    }
    notes: allMdx(
      sort: { order: DESC, fields: [frontmatter___createdAt] }
      filter: { frontmatter: { articleType: { eq: "note" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            createdAt(formatString: "DD MMMM YYYY", locale: "tr")
            tags
            title
          }
        }
      }
    }
  }
`
